var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-card-title',[_c('h3',{staticClass:"text-h3 text-uppercase"},[_vm._v(_vm._s(_vm.$t('orders.myOrders.pageTitle')))])]),_c('k-crud-table',{ref:"table",attrs:{"headers":_vm.crudHeaders,"rowsPerPage":5,"index-request":_vm.indexRequest,"language-prefix":"orders.crudHeaders","resource":"orders"},scopedSlots:_vm._u([{key:"item.statusIcon",fn:function(ref){
var orderStatusId = ref.item.orderStatusId;
return [_c('v-row',{staticClass:"justify-center align-center"},[_c('v-col',{staticStyle:{"height":"48px"},attrs:{"cols":"auto"}},[_c('OrderStatusIcon',{attrs:{"orderStatusId":orderStatusId}})],1)],1)]}},{key:"item.orderStatusId",fn:function(ref){
var orderStatusId = ref.item.orderStatusId;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("orders.orderStatus." + (_vm.getOrderStatus(orderStatusId))))))])]}},{key:"item.date",fn:function(ref){
var date = ref.item.date;
return [_c('span',[_vm._v(_vm._s(_vm.dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('LL')))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{name: 'my-orders.show', params: {orderId: item.id}}}},[_c('v-icon',[_vm._v("$visible")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }